import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Accordion, Lazyload, Lightbox } from '~/components'
import { Image } from '~/components/Lightbox'
import cn from 'classnames'
import * as st from '~/assets/styl/Plants.module.styl'

interface Plant {
  category: {
    name: string
  }
  name: string
  image: string
}

function group(xs: Array<Plant>) {
  return xs.reduce(function (rv, x) {
    ;(rv[x.category.name] = rv[x.category.name] || []).push(x)
    return rv
  }, {})
}

const Option = ({
  className,
  onClick,
  children,
}: {
  className?: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
  children: string
}) => {
  const mobile = !useMediaQuery({
    query: '(min-width: 951px)',
  })

  const [hover, setHover] = useState(false)

  return (
    <li className={cn(className, !mobile && hover && st.hover)}>
      <button
        onMouseEnter={mobile ? null : () => setHover(true)}
        onMouseLeave={mobile ? null : () => setHover(false)}
        onClick={onClick}
      >
        {children}
      </button>
    </li>
  )
}

const Plants = ({ plants }: { plants: Array<Plant> }) => {
  const plantsGrouped: {
    [key: string]: Array<Plant>
  } = group(plants)

  const [accordion, setAccordion] = useState(0)
  const [plant, setPlant] = useState(0)

  const [images, setImages] = useState<Array<Image>>()

  return plants.length ? (
    <>
      <section className={st.core}>
        <div className={st.container}>
          <h2>Plantas</h2>
          <div className={st.content}>
            <div>
              {Object.keys(plantsGrouped).map((key, index) => (
                <Accordion
                  key={index}
                  title={key}
                  open={accordion === index}
                  onOpen={() => setAccordion(index)}
                >
                  <ul className={st.selector}>
                    {plantsGrouped[key].map((thisPlant, key) => (
                      <Option
                        key={key}
                        className={
                          plant === plants.indexOf(thisPlant) && st.active
                        }
                        onClick={() => setPlant(plants.indexOf(thisPlant))}
                      >
                        {thisPlant.name}
                      </Option>
                    ))}
                  </ul>
                </Accordion>
              ))}
            </div>
            <div>
              <div className={st.plant}>
                {plants.map(({ image }, index) => (
                  <Lazyload
                    key={index}
                    src={image}
                    contain
                    position="absolute"
                    className={plant === index ? st.active : null}
                    onClick={() =>
                      setImages(plants.map((i) => ({ url: i.image })))
                    }
                  />
                ))}
              </div>
              <span>Clique na planta para ampliar</span>
            </div>
          </div>
        </div>
      </section>

      <Lightbox
        index={plant}
        images={images}
        setIndex={setPlant}
        setImages={setImages}
      />
    </>
  ) : (
    <></>
  )
}

export default Plants
