import React from 'react'
import { Button, Map } from '~/components'
import * as st from '~/assets/styl/Location.module.styl'

interface Props {
  location?: string
  children?: React.ReactNode
  latitude?: string
  longitude?: string
}

const Location = ({
  location = 'Chapecó - SC',
  children = (
    <>
      Rua Nome da rua completo, nº 800
      <br />
      Bairro, Chapecó - Santa Catarina
      <br />
      CEP: 88552-600
    </>
  ),
  latitude = '-27.10689042756634',
  longitude = '-52.607854876017264',
}: Props) => (
  <section className={st.core}>
    <h2>Localização</h2>
    <div className={st.content}>
      <div className={st.text}>
        <h3>{location}</h3>
        <p>{children}</p>
        <Button
          href={`https://www.google.com/maps?saddr=My+Location&daddr=${latitude},${longitude}`}
          external
          className={st.btn}
        >
          Traçar uma rota
        </Button>
      </div>
      <div className={st.map}>
        <Map location={{ lat: latitude, lng: longitude }} />
      </div>
    </div>
  </section>
)

export default Location
