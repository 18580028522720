import React, { useEffect, useState } from 'react'
import { useApi } from '~/siteApi'
import { useStaticQuery, graphql } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import { Link } from '~/components'
import { navItems } from '~/data'
import cn from 'classnames'
import * as st from '~/assets/styl/Hamburger.module.styl'

interface Props {
  open: boolean
  closeHamburger: () => void
}

const enterprises = navItems.find((i) => i.name === 'Empreendimentos')

const Hamburger = ({ open, closeHamburger }: Props) => {
  const query = useStaticQuery(graphql`
    query {
      allConfigurations {
        nodes {
          instagram
        }
      }
      allEnterprises {
        nodes {
          _id
          cover
          image
          slug
          name
          address
          district
          city
          state {
            name
            acronym
          }
          cep
          description
          category {
            name
          }
          completePlan
        }
      }
      allResales {
        nodes {
          name
        }
      }
    }
  `)

  const {
    result: config,
  }: {
    result: {
      instagram: string
    }
  } = useApi(
    {
      result: query.allConfigurations.nodes[0],
    },
    'configurations'
  )

  const {
    constructions: enterpriseArray,
  }: { constructions: Array<Enterprise> } = useApi(
    {
      constructions: query.allEnterprises.nodes,
    },
    'constructions'
  )
  const releases = enterpriseArray.filter(
    (i) => i.category?.name === 'Lançamento'
  )
  const underConstruction = enterpriseArray.filter(
    (i) => i.category?.name === 'Construção'
  )

  const { resales }: { resales: Array<never> } = useApi(
    {
      resales: query.allResales.nodes,
    },
    'resales'
  )

  const mobile = !useMediaQuery({
    query: '(min-width: 1001px)',
  })
  const [client, setClient] = useState(false)
  useEffect(() => {
    setClient(true)
  }, [])

  function subEnterprises(name: string) {
    let enterprises: Array<Enterprise> = []
    if (name === 'Lançamentos') enterprises = releases
    else if (name === 'Em construção') enterprises = underConstruction
    if (enterprises.length)
      return (
        <ul>
          {enterprises.map(({ name, slug }, key) => (
            <li key={key}>
              <Link
                href={`/empreendimento/${slug}/`}
                onClick={() => closeHamburger()}
              >
                {name}
              </Link>
            </li>
          ))}
        </ul>
      )
  }

  return (
    <nav
      itemScope
      itemType="http://www.schema.org/SiteNavigationElement"
      className={cn(st.core, open && st.active)}
    >
      <ul className={st.aside}>
        {[navItems[0], navItems[2], navItems[1], ...navItems.slice(3)]
          .filter((i) => mobile || i !== enterprises)
          .map(({ name, url, subItems }, key) => (
            <li key={key}>
              <Link href={url} onClick={() => closeHamburger()}>
                <span className="icon-list-arrow"></span>
                {name}
              </Link>
              {subItems && (!mobile || key === 1) && (
                <ul>
                  {subItems
                    .filter(({ name }) => resales.length || name !== 'Revenda')
                    .map(({ name, url }, key) => (
                      <li key={key}>
                        <Link href={url} onClick={() => closeHamburger()}>
                          {name}
                        </Link>
                      </li>
                    ))}
                </ul>
              )}
            </li>
          ))}
      </ul>

      {!mobile && client ? (
        <div className={st.enterprises}>
          <div className={st.close}>
            <button
              className="icon-close"
              onClick={() => closeHamburger()}
            ></button>
          </div>
          <Link href={enterprises.url} onClick={() => closeHamburger()}>
            <span className="icon-list-arrow"></span>
            {enterprises.name}
          </Link>
          <ul>
            {enterprises.subItems
              .filter(({ name }) => resales.length || name !== 'Revenda')
              .map(({ name, url }, key) => (
                <li key={key}>
                  <Link href={url} onClick={() => closeHamburger()}>
                    {name}
                  </Link>
                  {subEnterprises(name)}
                </li>
              ))}
          </ul>
          {config.instagram && (
            <div className={st.instagram}>
              <a
                href={config.instagram}
                target="_blank"
                rel="noreferrer"
                className="icon-instagram"
                onClick={() => closeHamburger()}
              ></a>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className={st.close}>
            <button
              className="icon-close"
              onClick={() => closeHamburger()}
            ></button>
          </div>
          {config.instagram && (
            <div className={st.instagram}>
              <a
                href={config.instagram}
                target="_blank"
                rel="noreferrer"
                className="icon-instagram"
                onClick={() => closeHamburger()}
              ></a>
            </div>
          )}
        </>
      )}
    </nav>
  )
}

export default Hamburger
