import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Helmet from 'react-helmet'
import cn from 'classnames'
import * as st from '~/assets/styl/Video.module.styl'

import Youtube, { YouTubeProps } from 'react-youtube'

const Image = styled.div<{ src: string }>`
  top: 0;
  left: 0;
  right: -1px;
  bottom: -1px;
  position: absolute;
  ${(props) =>
    props.src &&
    css`
      background: url(${props.src}) center / cover;
    `}
`

export function getVideoId(url: string) {
  const urlParts = url.split(/(youtu\.be\/)|(youtube\.com\/watch\?v=)/)
  return urlParts[urlParts.length - 1]
}

type Props = {
  src: string
  thumbnail?: string
  className?: string
}

const Video = ({ src: url, thumbnail, className }: Props) => {
  const [playing, setPlaying] = useState(false)

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    if (playing) {
      event.target.playVideo()
    }
  }

  return (
    <>
      <Helmet>
        <script src="https://www.youtube.com/iframe_api"></script>
      </Helmet>

      <div className={cn(st.core, playing && st.playing, className)}>
        <Image
          src={
            thumbnail || `https://img.youtube.com/vi/${getVideoId(url)}/0.jpg`
          }
          onClick={() => setPlaying(true)}
          className={st.thumbnail}
        />
        {playing && (
          <Youtube
            videoId={getVideoId(url)}
            onReady={onPlayerReady}
            onPlay={onPlayerReady}
          />
        )}
      </div>
    </>
  )
}

export default Video

