import React from 'react'
import * as st from '~/assets/styl/AttendanceForm.module.styl'

interface Props {
  title?: string
  children: React.ReactNode
  id?: string
}

const AttendanceForm = ({ title, children, id }: Props) => {
  return (
    <section className={st.core} id={id}>
      <h2>{title}</h2>
      <p>
        Entre em contato e solicite um atendimento com nosso time de
        consultores.
      </p>
      {children}
    </section>
  )
}

export default AttendanceForm
