// extracted by mini-css-extract-plugin
export var active = "About-module--active--5a695";
export var chart = "About-module--chart--7913e";
export var constructions = "About-module--constructions--5fdde";
export var container = "About-module--container--4cac8";
export var content = "About-module--content--57203";
export var done = "About-module--done--53b0c";
export var heading = "About-module--heading--e54e5";
export var icon = "About-module--icon--8b16e";
export var legend = "About-module--legend--3d55e";
export var numbers = "About-module--numbers--0b188";
export var plan = "About-module--plan--f3da9";
export var quality = "About-module--quality--d6ea5";
export var services = "About-module--services--14cbc";
export var tabs = "About-module--tabs--7de99";
export var values = "About-module--values--d56cd";
export var whoWeAre = "About-module--who-we-are--cd261";
export var why = "About-module--why--dcbd9";