import React from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import * as st from '~/assets/styl/Map.module.styl'
import Pin from '~/assets/svg/Marcador.svg'
import PinResale from '~/assets/svg/Marcador-revenda.svg'

interface Props {
  location: {
    lat: string
    lng: string
  }
  resale?: boolean
}

const Map = ({ location: { lat, lng }, resale }: Props) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAwxoGl33FO9yYDxrmAzQUSdWoSXr1UoE4',
  })

  return isLoaded ? (
    <GoogleMap
      zoom={13}
      center={{
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      }}
      options={{
        fullscreenControl: false,
        zoomControl: false,
        mapTypeControl: false,
        streetViewControl: false,
      }}
      mapContainerClassName={st.container}
    >
      <Marker
        icon={
          resale
            ? {
                url: PinResale,
                anchor: new google.maps.Point(22.5, 60),
                size: new google.maps.Size(45, 60),
              }
            : {
                url: Pin,
                anchor: new google.maps.Point(30, 83),
                size: new google.maps.Size(60, 83),
              }
        }
        clickable={false}
        position={{
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        }}
      />
    </GoogleMap>
  ) : (
    <></>
  )
}

export default Map
