import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { Link, Button } from '~/components'
import cn from 'classnames'
import * as st from '~/assets/styl/CookieBar.module.styl'

export default function CookieBar() {
  const [cookies, setCookie] = useCookies()
  const [enabled, setEnabled] = useState(false)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (!cookies?.acceptCookies && !visible) {
      setEnabled(true)
      setTimeout(() => setVisible(true), 100)
    } else if (cookies?.acceptCookies && visible) {
      setVisible(false)
      setTimeout(() => setEnabled(false), 300)
    }
  }, [cookies?.acceptCookies])

  return enabled ? (
    <div className={cn(st.core, visible && st.visible)}>
      <p>
        Utilizamos cookies para oferecer melhor experiência, melhorar o
        desempenho, analisar como você interage em nosso site e personalizar
        conteúdo.
        <br />
        Ao utilizar este site, você concorda com nossa{' '}
        <Link href="/politica-de-privacidade/">
          política de privacidade
        </Link> e <Link href="/termos-de-uso/">termos de uso</Link>.
      </p>
      <Button
        type="small"
        onClick={() => setCookie('acceptCookies', true, { path: '/' })}
      >
        Continuar navegando
      </Button>
    </div>
  ) : (
    <></>
  )
}
