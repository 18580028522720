import React, { useState } from 'react'
import { useApi } from '~/siteApi'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from '~/components'
import { navItems } from '~/data'
import { useMediaQuery } from 'react-responsive'
import cn from 'classnames'
import * as st from '~/assets/styl/Footer.module.styl'
import * as st1 from '~/assets/styl/MenuBar.module.styl'

const Footer = () => {
  const query = useStaticQuery(graphql`
    query {
      allConfigurations {
        nodes {
          facebook
          instagram
          linkedin
          whatsapp
          phone
          youtube
        }
      }
      allResales {
        nodes {
          name
        }
      }
    }
  `)

  const {
    result: config,
  }: {
    result: {
      facebook?: string
      instagram?: string
      linkedin?: string
      youtube?: string
      whatsapp: string
      phone: string
    }
  } = useApi(
    {
      result: query.allConfigurations.nodes[0],
    },
    'configurations'
  )

  const { resales }: { resales: Array<never> } = useApi(
    {
      resales: query.allResales.nodes,
    },
    'resales'
  )

  const mobile = !useMediaQuery({
    query: '(min-width: 901px)',
  })

  const anoAtual = useState(new Date().getFullYear())

  return (
    <footer className={st.core}>
      <div className={st.container}>
        <div className={st.topBlock}>
          <div>
            <strong>
              Holder
              <br />
              Construtora
            </strong>
            <p>
              Av. Fernando Machado - D , 1261
              <br />
              Centro, Chapecó - SC, 89802-111
            </p>
            <a
              href={`tel:${config.phone.replace(/\D/g, '')}`}
              className={st.phone}
            >
              <span className="icon-phone"></span>
              {config.phone}
            </a>
          </div>
          <ul className={st.nav}>
            {navItems.map(({ name, url, subItems }, key) => (
              <li key={key}>
                <Link href={url}>{name}</Link>
                {subItems && (
                  <ul>
                    {subItems
                      .filter(
                        ({ name }) => resales.length || name !== 'Revenda'
                      )
                      .map(({ name, url }, key) => (
                        <li key={key}>
                          <Link href={url}>{name}</Link>
                        </li>
                      ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          <ul className={st.socials}>
            {config.instagram && (
              <li>
                <Link
                  href={config.instagram}
                  external
                  className="icon-instagram"
                ></Link>
              </li>
            )}
            {config.facebook && (
              <li>
                <Link
                  href={config.facebook}
                  external
                  className="icon-facebook"
                ></Link>
              </li>
            )}
            {config.linkedin && (
              <li>
                <Link
                  href={config.linkedin}
                  external
                  className="icon-linkedin"
                ></Link>
              </li>
            )}
            {config.youtube && (
              <li>
                <Link
                  href={config.youtube}
                  external
                  className="icon-youtube"
                ></Link>
              </li>
            )}
          </ul>
        </div>
        <div className={st.bottomBlock}>
          <div className={st.copy}>
            {!mobile && (
              <span>
                <>
                  © {anoAtual} Holder Construtora. Todos os direitos reservados.
                </>
              </span>
            )}
            <Link href="/politica-de-privacidade/">
              Política de privacidade
            </Link>
            <Link href="/termos-de-uso/">Termos de uso</Link>
            {mobile && (
              <span>
                © 2022 Holder Construtora.
                <br />
                Todos os direitos reservados.
              </span>
            )}
          </div>
          <a
            href={`https://api.whatsapp.com/send?phone=55${config.whatsapp.replace(
              /\D/g,
              ''
            )}`}
            target="_blank"
            rel="noreferrer"
            className={cn(st1.whatsapp, st.whatsapp)}
          >
            <span className="icon-whatsapp"></span> WhatsApp
          </a>
        </div>
        <div className={st.tiki}>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href="https://www.tiki.com.br" target="_blank">
            Tiki
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
