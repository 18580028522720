import React from 'react'
import { Button, Logo } from '~/components'
import * as st from '~/assets/styl/HomeAbout.module.styl'
import cn from 'classnames'

const HomeAbout = () => (
  <section className={st.core}>
    <div className={cn(st.block, st.who)}>
      <div>
        <picture>
          <Logo />
        </picture>
        <h2>Quem Somos</h2>
        <p>
          Bem-vindo à Holder, onde excelência é o nosso padrão de construção.
          <br />
          <br />
          Com anos de experiência e um compromisso inabalável com a qualidade,
          estamos dedicados a criar espaços que estabeleçam novos padrões na
          indústria da construção.
          <br />
          <br />
          Nossa abordagem é construída sobre os pilares da excelência em cada
          etapa do processo de construção. Desde a concepção até a entrega
          final, cada detalhe é meticulosamente planejado e executado para
          garantir resultados que superam as expectativas de nossos clientes.
        </p>
        <div className={st.excelence}></div>
      </div>
      <div className={st.whoWeAre}></div>
    </div>
    <div className={st.block}>
      <div>
        <h2>
          Qualidade,
          <br />
          Credibilidade
          <br />e Inovação.
        </h2>
        <p>
          Nossa equipe segue sempre em busca de novidades para garantir cada vez
          mais a satisfação de nossos clientes.
        </p>
        <Button href="/empreendimentos/">Empreendimentos</Button>
      </div>
      <div className={st.quality}></div>
    </div>
  </section>
)

export default HomeAbout
