import React, { useState } from 'react'
import { Lazyload, Lightbox } from '~/components'
import { Image } from '~/components/Lightbox'
import * as st from '~/assets/styl/EnterpriseListing.module.styl'
import * as st1 from '~/assets/styl/EnterpriseSlider.module.styl'

interface Props {
  id?: string
  title: string
  enterprises: Array<Enterprise>
  style?: React.CSSProperties
}

const EnterpriseListing = ({ id, title, enterprises, style }: Props) => {
  const [images, setImages] = useState<Array<Image>>()
  const [index, setIndex] = useState(0)

  return (
    <>
      <section className={st1.core} style={style} id={id}>
        <div className={st1.container}>
          <h2>{title}</h2>
          <ul className={st.enterprises}>
            {enterprises.map(
              (
                {
                  cover,
                  image,
                  completePlan: year,
                  name,
                  district,
                  city,
                  state,
                  photos,
                },
                key
              ) => (
                <li key={key}>
                  <div
                    className={st.enterprise}
                    onClick={() =>
                      photos?.length &&
                      setImages(
                        photos.map(({ zoom: url, legend }) => ({ url, legend }))
                      )
                    }
                    style={photos?.length ? { cursor: 'pointer' } : null}
                  >
                    <Lazyload src={image || cover} className={st.image} />
                    {year && <p className={st.delivery}>Entregue em {year}</p>}
                    <h3>{name}</h3>
                    <p className={st.location}>
                      {district ? district + ' - ' : ''}
                      {city}
                      {state ? ' - ' + state.acronym : ''}
                    </p>
                  </div>
                </li>
              )
            )}
          </ul>
        </div>
      </section>

      <Lightbox
        images={images}
        index={index}
        setImages={setImages}
        setIndex={setIndex}
      />
    </>
  )
}

export default EnterpriseListing
