import React from 'react'
import nl2br from 'react-nl2br'
import Slider, { ArrowType } from '@fil1pe/react-slider'
import { useMediaQuery } from 'react-responsive'
import { Lazyload, Button } from '~/components'
import cn from 'classnames'
import * as st from '~/assets/styl/EnterpriseSlider.module.styl'

interface Props {
  id?: string
  title?: string
  enterprises: Array<Enterprise>
  style?: React.CSSProperties
}

const EnterpriseSlider = ({
  id,
  title = 'Empreendimentos',
  enterprises,
  style,
}: Props) => {
  const mobile = !useMediaQuery({
    query: '(min-width: 951px)',
  })

  return (
    <section className={st.core} style={style} id={id}>
      <div className={st.container}>
        <h2>{title}</h2>
        <Slider
          className={cn('slider', st.slider)}
          renderArrow={({ className, ...props }, type) => (
            <button
              {...props}
              className={cn(
                className,
                type === ArrowType.Next ? 'next' : 'prev'
              )}
            ></button>
          )}
          slidesToAppend={1}
        >
          {enterprises.map(
            (
              {
                image,
                cover,
                name,
                category,
                district,
                city,
                description,
                slug,
              },
              key
            ) => (
              <div className={st.enterprise} key={key}>
                <Lazyload src={image || cover} className={st.image} />
                <div className={st.text}>
                  {mobile && (
                    <p className={st.location}>
                      {category ? category.name + ' - ' : ''}
                      {district} - {city}
                    </p>
                  )}
                  <h3>{name}</h3>
                  {!mobile && (
                    <p className={st.location}>
                      {category ? category.name + ' - ' : ''}
                      {district} - {city}
                    </p>
                  )}
                  <p>{nl2br(description)}</p>
                  <Button href={`/empreendimento/${slug}/`} className={st.btn}>
                    Conheça
                  </Button>
                </div>
              </div>
            )
          )}
        </Slider>
      </div>
    </section>
  )
}

export default EnterpriseSlider
