import React from 'react'
import { navigate } from 'gatsby'
import { Link } from '~/components'
import cn from 'classnames'
import * as st from '~/assets/styl/Breadcrumb.module.styl'

interface Props {
  empty?: boolean
  className?: string
}

const Breadcrumb = ({ empty, className }: Props) => {
  return (
    <div className={cn(st.core, className)}>
      {empty ? (
        <></>
      ) : (
        <>
          <Link href="/">
            <span className="icon-list-arrow"></span>
            Home
          </Link>
          <button onClick={() => navigate(-1)}>
            <span className="icon-list-arrow"></span>
            Voltar
          </button>
        </>
      )}
    </div>
  )
}

export default Breadcrumb
