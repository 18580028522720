import React from 'react'
import Slider, { ArrowType } from '@fil1pe/react-slider'
import { useApi } from '~/siteApi'
import { useStaticQuery, graphql } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import cn from 'classnames'
import * as st from '~/assets/styl/Testimonials.module.styl'
import * as st1 from '~/assets/styl/EnterpriseSlider.module.styl'

interface Props {
  style?: React.CSSProperties
}

interface Testimonial {
  image: string
  name: string
  office: string
  text: string
}

const Testimonials = ({ style }: Props) => {
  const { result: testimonials }: { result: Array<Testimonial> } = useApi(
    {
      result: useStaticQuery(graphql`
        query {
          allTestimonials {
            nodes {
              image
              name
              office
              text
            }
          }
        }
      `).allTestimonials.nodes,
    },
    'depositions'
  )

  const mobile = !useMediaQuery({
    query: '(min-width: 951px)',
  })

  return (
    <section className={st1.core} style={style}>
      <div className={st1.container}>
        <h2>Depoimentos</h2>
        <Slider
          slidesToShow={mobile ? 1 : 3}
          className={cn('slider', st.slider)}
          renderArrow={({ className, ...props }, type) => (
            <button
              {...props}
              className={cn(
                className,
                type === ArrowType.Next ? 'next' : 'prev'
              )}
            ></button>
          )}
          slidesToAppend={mobile ? 3 : 0}
        >
          {testimonials.map(({ name, office, text, image }, key) => (
            <div className={st.testimonial} key={key}>
              <p>{text}</p>
              <div className={st.author}>
                <img src={image} width="60" height="60" alt={name} />
                <p>
                  {name}
                  <br />
                  {office}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}

export default Testimonials
