import React from 'react'
import nl2br from 'react-nl2br'
import * as st from '~/assets/styl/Certifications.module.styl'
import Iso from '~/assets/svg/ISO-9001-2015.svg'
import Siac from '~/assets/svg/SIAC-2018.svg'

const certificates = [
  {
    name: 'ISO 9001/2015',
    description:
      'O selo é um sistema de gestão de qualidade, com o objetivo de garantir a otimização de processos, maior agilidade no desenvolvimento de produtos e produção mais ágil, a fim de satisfazer os clientes e as normas.',
    image: Iso,
  },
  {
    name: 'SIAC 2021',
    description:
      'Credibilidade empresarial e compromisso com a qualidade.\nPrograma do governo federal onde construtoras são certificadas, facilitando o processo de financiamentos de obras junto a Caixa Econômica Federal. Bancos privados que apoiam a produção de construtoras também exigem esse certificado.',
    image: Siac,
  },
]

const Certifications = () => (
  <section className={st.core}>
    <div className={st.text}>
      <h2>Certificações</h2>
      <p>
        Buscar Excelência é parte do DNA da Holder, e é por essa razão que somos
        uma empresa certificada pelos melhores padrões internacionais de
        qualidade.
      </p>
      <ul>
        {certificates.map(({ name, description, image }, key) => (
          <li key={key}>
            <img width="100" height="100" src={image} />
            <div>
              <h3>{name}</h3>
              <p>{nl2br(description)}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
    <div className={st.image}></div>
  </section>
)

export default Certifications
