export default [
  {
    name: 'Home',
    url: '/',
    menuBar: true,
  },
  {
    name: 'Sobre',
    url: '/sobre/',
    menuBar: true,
  },
  {
    name: 'Empreendimentos',
    url: '/empreendimentos/',
    menuBar: true,
    subItems: [
      {
        name: 'Lançamentos',
        url: '/empreendimentos/#Lancamentos',
      },
      {
        name: 'Em construção',
        url: '/empreendimentos/#EmConstrucao',
      },
      {
        name: 'Entregues',
        url: '/empreendimentos/#Entregues',
      },
      {
        name: 'House',
        url: '/house/',
      },
      {
        name: 'Revenda',
        url: '/revenda/',
      },
    ],
  },
  {
    name: 'Vendas',
    url: '/vendas/',
    menuBar: true,
  },
  {
    name: 'Contato',
    url: '/contato/',
    subItems: [
      {
        name: 'Fale conosco',
        url: '/contato/',
      },
      {
        name: 'Trabalhe conosco',
        url: '/trabalhe-conosco/',
      },
      {
        name: 'Pós-obra',
        url: '/pos-obra/',
      },
    ],
  },
]
