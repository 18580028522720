import React from 'react'
import styled, { css } from 'styled-components'
import { Breadcrumb } from '~/components'
import nl2br from 'react-nl2br'
import cn from 'classnames'
import * as st from '~/assets/styl/Hero.module.styl'
import Film from '~/assets/img/Banner-pelicula.png'

const Image = styled.div<{ src: string }>`
  padding-top: 54%;
  ${(props) =>
    props.src &&
    css`
      background: url(${Film}) no-repeat center / cover,
        url(${props.src}) center / cover;
    `}
`

interface Props {
  title: string
  image: string
  description: string
  children?: React.ReactNode
  type?: string
}

const Hero = ({ title, image, description, children, type }: Props) => (
  <header className={cn(st.core, type && st[type])}>
    <Breadcrumb empty={type === 'notFound'} />
    <div className={st.container}>
      <div className={st.text}>
        <span className={st.pretitle}>Holder Construtora e Incorporadora</span>
        <h1 className={st.title}>{title}</h1>
        <span className={st.subtitle}>{nl2br(description)}</span>
        {children}
      </div>
      <Image src={image} className={st.image} />
    </div>
  </header>
)

export default Hero
