import React, { useState, useEffect } from 'react'
import { useApi } from '~/siteApi'
import { useStaticQuery, graphql } from 'gatsby'
import { Link, Logo } from '~/components'
import { navItems } from '~/data'
import cn from 'classnames'
import * as st from '~/assets/styl/MenuBar.module.styl'

interface Props {
  location: Location
  openHamburger: () => void
}

const MenuBar = ({ location: { pathname }, openHamburger }: Props) => {
  const {
    result: config,
  }: {
    result: {
      whatsapp: string
    }
  } = useApi(
    {
      result: useStaticQuery(graphql`
        query {
          allConfigurations {
            nodes {
              whatsapp
            }
          }
        }
      `).allConfigurations.nodes[0],
    },
    'configurations',
    true
  )

  const [transparent, setTransparent] = useState(true)
  useEffect(() => {
    const onScroll = () => {
      setTransparent(!window.scrollY)
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <div
      className={cn(
        st.core,
        transparent &&
          (pathname === '/' || pathname.startsWith('/empreendimento/')) &&
          st.transparent
      )}
      id="menu-bar"
    >
      <div className={st.container}>
        <Link href="/">
          <Logo />
        </Link>
        <div className={st.nav}>
          <ul>
            {navItems
              .filter((i) => i.menuBar)
              .map(({ name, url }, key) => (
                <li key={key}>
                  <Link href={url}>
                    <span className="icon-list-arrow"></span>
                    {name}
                  </Link>
                </li>
              ))}
          </ul>
        </div>
        <a
          href={`https://api.whatsapp.com/send?phone=55${config.whatsapp.replace(
            /\D/g,
            ''
          )}`}
          target="_blank"
          rel="noreferrer"
          className={st.whatsapp}
        >
          <span className="icon-whatsapp"></span> WhatsApp
        </a>
        <button className={st.hamburger} onClick={() => openHamburger()}>
          <span className="icon-hamburger"></span>
        </button>
      </div>
    </div>
  )
}

export default MenuBar
