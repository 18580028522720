// extracted by mini-css-extract-plugin
export var bottomBlock = "Footer-module--bottom-block--45a24";
export var container = "Footer-module--container--d66d8";
export var copy = "Footer-module--copy--0ea37";
export var core = "Footer-module--core--340e5";
export var nav = "Footer-module--nav--7d35c";
export var phone = "Footer-module--phone--ae75b";
export var socials = "Footer-module--socials--a5672";
export var tiki = "Footer-module--tiki--bc2cd";
export var topBlock = "Footer-module--top-block--50823";
export var whatsapp = "Footer-module--whatsapp--be50d";