import React, { useState } from 'react'
import cn from 'classnames'
import * as st from '~/assets/styl/Tabs.module.styl'

interface Props {
  defaultPage?: number
  pages: Array<string>
  children: React.ReactNode
  classes?: {
    selector?: string
    content?: string
  }
}

const Tabs = ({ defaultPage = 0, pages: _pages, children, classes }: Props) => {
  const pages = _pages.filter((i) => i)
  const [page, setPage] = useState(defaultPage)

  return (
    <>
      {pages.length > 1 && (
        <div className={cn(st.tabs, classes?.selector)}>
          {pages.map((tab, index) => (
            <button
              onClick={() => setPage(index)}
              className={index === page ? st.active : null}
              key={index}
            >
              {tab}
            </button>
          ))}
        </div>
      )}
      <div className={cn(st.content, classes?.content)}>
        {React.Children.toArray(children)
          .filter((i) => i)
          .map(
            (
              child: React.DetailedReactHTMLElement<
                React.HTMLAttributes<HTMLElement>,
                HTMLElement
              >,
              index
            ) =>
              React.cloneElement(child, {
                className: cn(
                  child.props.className,
                  index === page && st.active
                ),
              })
          )}
      </div>
    </>
  )
}

export default Tabs
