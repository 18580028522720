import React, { useEffect, useState } from 'react'
import { Button } from '~/components'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { useApi } from '~/siteApi'
import * as st from '~/assets/styl/HomeHero.module.styl'
import * as st1 from '~/assets/styl/Hero.module.styl'

const Image = styled.div<{ src: string; visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  transition: opacity 0.4s;
  ${(props) =>
    props.src &&
    css`
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0,
          rgba(0, 0, 0, 0) 150px
        ),
        linear-gradient(
          0deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) 56%,
          rgba(0, 0, 0, 0.5) 100%
        ),
        url(${props.src}) center / cover;
    `}
  ${(props) =>
    !props.visible &&
    css`
      opacity: 0;
      z-index: 1;
      pointer-events: none;
    `}
`

interface Showcase {
  title: string
  subtitle?: string
  text?: string
  image: string
  text_button?: string
  link_button?: string
  external: boolean
  relative: boolean
}

const HomeHero = () => {
  const { result: showcases }: { result: Array<Showcase> } = useApi(
    {
      result: useStaticQuery(graphql`
        query {
          allShowcases {
            nodes {
              title
              subtitle
              text
              text_button
              link_button
              image
              external
              relative
            }
          }
        }
      `).allShowcases.nodes,
    },
    'showcases'
  )

  const [page, setPage] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(
      () => setPage((page + 1) % showcases.length),
      4000
    )
    return () => clearInterval(intervalId)
  }, [page])

  return (
    <header className={st.core}>
      <div className={st.banners}>
        {showcases.map(
          (
            {
              subtitle,
              title,
              text,
              text_button,
              link_button,
              external,
              relative,
              image,
            },
            index
          ) => (
            <Image
              src={image}
              visible={page === index}
              className={st.banner}
              key={index}
            >
              <div className={st.container}>
                {subtitle && <span className={st1.pretitle}>{subtitle}</span>}
                <h3 className={st1.title}>{title}</h3>
                {text && <span className={st1.subtitle}>{text}</span>}
                {Boolean(text_button && link_button) && (
                  <Button
                    href={link_button}
                    external={external || !relative}
                    className={st1.button}
                    type="big"
                  >
                    {text_button}
                  </Button>
                )}
              </div>
            </Image>
          )
        )}
      </div>
      <div className={st.tabs}>
        {showcases.map(({ title }, index) => (
          <button
            className={page === index ? st.active : null}
            onClick={() => setPage(index)}
            key={index}
          >
            {title}
          </button>
        ))}
      </div>
    </header>
  )
}

export default HomeHero
