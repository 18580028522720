import React from 'react'
import { useApi } from '~/siteApi'
import { useStaticQuery, graphql } from 'gatsby'
import nl2br from 'react-nl2br'
import cn from 'classnames'
import * as st from '~/assets/styl/About.module.styl'

interface Props {
  className?: string
}

interface OurNumber {
  value: string
  subtitle: string
}

const Numbers = ({ className }: Props) => {
  const { result: numbers }: { result: Array<OurNumber> } = useApi(
    {
      result: useStaticQuery(graphql`
        query {
          allNumbers {
            nodes {
              value
              subtitle
            }
          }
        }
      `).allNumbers.nodes,
    },
    'numbers'
  )

  return (
    <section className={cn(st.numbers, className)}>
      <div className={st.heading}>
        <h2>Nossos Números</h2>
      </div>
      <div>
        {numbers.map(({ value, subtitle }, key) => (
          <div key={key}>
            <strong>{value}</strong>
            <p>{nl2br(subtitle)}</p>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Numbers
