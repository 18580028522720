import { ReactNode, DetailedHTMLProps, HTMLAttributes } from 'react'
import UntypedLazyload from './Lazyload'

type LazyloadProps = {
  src: string
  children?: ReactNode
  position?: 'relative' | 'absolute' | 'fixed'
  contain?: boolean
} & (
  | ({
      type?: 'div' | undefined
    } & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>)
  | ({
      type: 'li'
    } & DetailedHTMLProps<HTMLAttributes<HTMLLIElement>, HTMLLIElement>)
)

export const Lazyload = UntypedLazyload as (props: LazyloadProps) => JSX.Element

export { default as Accordion } from './Accordion'
export { default as AttendanceForm } from './AttendanceForm'
export { default as Button } from './Button'
export { default as Breadcrumb } from './Breadcrumb'
export { default as Certifications } from './Certifications'
export { default as ContactForms } from './ContactForms'
export { default as CookieBar } from './CookieBar'
export { default as EnterpriseListing } from './EnterpriseListing'
export { default as EnterpriseSlider } from './EnterpriseSlider'
export { default as Footer } from './Footer'
export { default as Form } from './Form'
export { default as Gallery } from './Gallery'
export { default as Habita } from './Habita'
export { default as Hamburger } from './Hamburger'
export { default as Head } from './Head'
export { default as Hero } from './Hero'
export { default as HomeAbout } from './HomeAbout'
export { default as HomeHero } from './HomeHero'
export { default as Lightbox } from './Lightbox'
export { default as Link } from './Link'
export { default as Loading } from './Loading'
export { default as Location } from './Location'
export { default as Logo } from './Logo'
export { default as Map } from './Map'
export { default as Modal } from './Modal'
export { default as MenuBar } from './MenuBar'
export { default as Numbers } from './Numbers'
export { default as Plants } from './Plants'
export { default as Tabs } from './Tabs'
export { default as Testimonials } from './Testimonials'
export { default as Video } from './Video'
