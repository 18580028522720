import React from 'react'
import { Link } from '~/components'
import { GatsbyLinkProps } from 'gatsby'
import cn from 'classnames'
import * as st from '~/assets/styl/Button.module.styl'

type Props = Omit<
  Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'>,
  'to'
> & {
  href?: string
  onClick?: React.MouseEventHandler
  external?: boolean
  type?: 'normal' | 'big' | 'small'
}

const Button = ({
  href,
  onClick,
  children,
  className,
  type = 'normal',
  ...props
}: Props) =>
  href ? (
    <Link
      href={href}
      onClick={onClick}
      className={cn(st.core, st[type], className)}
      {...props}
    >
      <span className="icon-list-arrow"></span>
      <span className="icon-list-arrow"></span>
      {children}
    </Link>
  ) : (
    <button onClick={onClick} className={cn(st.core, st[type], className)}>
      <span className="icon-list-arrow"></span>
      <span className="icon-list-arrow"></span>
      {children}
    </button>
  )

export default Button
